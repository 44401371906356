import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { equals } from 'ramda';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Details from '../components/Details';

import '../styles/3_pages/thankyou.scss';

import thankyou from '../images/thankyou.jpg';

const ThankyouPage = ({ location }) => {
  const passedState = location.state || {};

  useEffect(() => {
    if (equals(passedState, {})) {
      navigate('/');
    }
  }, []);

  return (
    <Layout location={location} cta={false} short grey>
      <SEO title="Thank you" />
      <Details
        thankYou
        location={location}
        heading="Your Subscription Confirmation "
        paragraph="Congratulations! Your subscription is active. Be sure to check your inbox for regular notifications on your selected financial products.
        Remember ...you can update your preferences to tailor your notifications at any time!"
      >
        <div className="thankyou">
          <div className="thankyou__wrapper">
            <img src={thankyou} alt="Thank You" />
          </div>
        </div>
      </Details>
    </Layout>
  );
};

ThankyouPage.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default ThankyouPage;
